#login {
  max-width: 440px;
  margin: auto;
  font-family: "Roboto";
  padding: 10px;

  .logo {
    max-width: 80%;
    padding-bottom: 30px;
    margin-top: -80px;
  }

  .ant-form {
    margin: auto;

    @media (min-width: 426px) {
      border: 1px solid #bfbfbf;
      border-radius: 10px;
    }
  }

  .main-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    padding: 20px 20px 0 20px;

    h1 {
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      padding: 0 0 28px 0;
    }
  }

  .login-container {
    background-color: #ffffff;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    input {
      &.ant-input {
        padding: 15px;
        border-radius: 4px;
      }
    }

    .policy-checkbox {
      display: none;
    }

    .ant-input-affix-wrapper {
      border-radius: 4px;

      input {
        &.ant-input {
          padding: 15px;
          border-radius: 0px;
        }
      }

      .ant-input-suffix {
        padding: 15px;
      }
    }

    .ant-btn {
      &.ant-btn-block {
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
      }
    }

    .ant-checkbox + span {
      font-weight: 700;
      line-height: 14px;
    }

    @media (min-width: 426px) {
      border-top: 1px solid #bfbfbf;
    }
  }
}
