#datapoints {
  .content {
    padding-top: 10px;

    h3 {
      &.total {
        padding: 5px 10px;
        background: #fff;
        margin-bottom: 0px;

        small {
          color: #00aaf1;
          font-size: 100%;
        }
      }
    }

    p {
      span {
        &.status-title {
          min-width: 200px;
          display: inline-block;
        }
      }

      .status-count {
        font-weight: bold;
      }
    }

    .ant-table {
      .normalize {
        font-weight: 500;
        color: #000;
      }

      .datapoint-name,
      .submitter {
        font-weight: bold;
      }

      .datapoint-name {
        color: #1890ff;
      }

      .row-dark {
        background-color: #f7f8fc;
      }
    }
  }
}
