@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  font-family: "Roboto", sans-serif;
  height: 100vh;

  .root-container {
    background-color: #f7f8fc;

    @media (max-width: 426px) {
      background-color: #fff;
    }
  }

  .loading-container {
    position: absolute;
    z-index: 1;
    width: 100vw;
    height: calc(100vh - 78px);
    text-align: center;

    .ant-spin {
      position: absolute;
      top: 50%;
    }
  }

  button {
    &.ant-btn {
      line-height: 0px;
      font-weight: 500;
      border-radius: 5px;
    }
  }

  .ant-tabs {
    .ant-tabs-tab {
      font-weight: 500;
      font-size: 1.17em;
    }
  }

  /* WEBFORM */
  .arf-container {
    .arf-repeat-input {
      &.arf-field-title,
      .arf-field {
        display: inline-block;
      }
    }
  }

  @media (min-width: 786px) {
    background-color: #f7f8fc;
  }
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

#___reactour {
  .mask {
    color: #2e2c2c;
    opacity: 0.5;
  }
}
