.header {
  background-color: #ffffff;
  padding: 15px 20px;
  box-shadow: 0 0 3px 0px #888888;
  border-bottom: 1px solid #bfbfbf;

  @media (min-width: 426px) {
    padding: 15px 70px;
  }

  .header-container {
    .header-wrapper {
      justify-content: space-between;
    }

    .header-menu {
      .header-logo {
        .ant-image-img {
          height: 50px;
          padding-right: 50px;
        }
      }

      .ant-breadcrumb {
        display: inline-block;
      }
    }

    .ant-btn {
      border-radius: 4px;
    }

    .user {
      text-align: right;
      text-align: -webkit-right;
    }

    .user-info {
      width: fit-content;
      cursor: pointer;
      margin: 0;

      .ant-dropdown-trigger {
        span {
          font-size: 18px;

          &.anticon {
            font-size: 12px;
          }
        }

        .ant-avatar {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

.breadcrumb-links {
  padding: 15px 20px;

  @media (min-width: 426px) {
    padding: 15px 70px;
  }

  .ant-breadcrumb-link {
    font-size: 18px;
  }
}

.main {
  min-height: calc(100vh - 78px);
  max-width: 100vw;
  overflow-x: hidden;

  h1 {
    line-height: normal;
    font-weight: bold;
  }

  .approved {
    color: #27ae60;
  }

  .rejected {
    color: #c7302b;
  }

  .pending {
    color: #c7302b;
  }

  .intro-container {
    padding: 20px 70px;

    h3 {
      padding: 5px 10px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 3px 0px #888888;
      margin-bottom: 0px;

      small {
        font-size: 100%;
      }
    }
  }

  .content-container {
    padding: 30px 0;

    .content {
      background: #fff;
      padding: 25px;

      p {
        font-size: 1.2em;
      }
    }

    @media (min-width: 426px) {
      padding: 3px 70px 30px;

      .content {
        border-radius: 10px;
        box-shadow: 0 0 3px 0px #888888;
      }
    }
  }
}
